import React from "react";


const Privacy = () => {
  
  return (
    <section id="privacy" style={{backgroundColor: "#fff", paddingTop: "60px", paddingBottom: "60px"}}>
      <div className="container" >
        {/* Heading */}
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
         Política de privacidad
        </h2>
        {/* Heading end*/}

        <div class="section-box animate-up animated">
  
	      	<div class="clearfix">
            <p>El Titular le informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios  que puedan ser recabados durante la navegación a través del Sitio Web: <a href="https://roberherraiz.com">https://roberherraiz.com</a>
            </p>

            <p>
              En este sentido, el Titular garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).
            </p>

            <p>
              El uso de sitio Web implica la aceptación de esta Política de Privacidad&nbsp;así como las condiciones incluidas en el &nbsp;<a href="/legal">Aviso Legal</a>.
            </p>

            <h2>Identidad del Responsable</h2>
            <p>
              </p><ul>    
                <li>
                  <b>Responsable:</b>&nbsp;   
                  Roberto Alonso Herraiz.
                  
                </li>
                


                <li>
                  <b>Domicilio:</b>&nbsp;
                  Santa Cruz de Tenerife - España.
                </li>
                
                <li>
                  <b>Correo electrónico:</b>&nbsp;
                  hello@roberherraiz.com
                </li>
                

                <li>
                  <b>Sitio Web:</b>&nbsp;
                  <a href="https://roberherraiz.com">https://roberherraiz.com</a>
                </li>
              </ul>
            <p></p>


            <h2>Principios aplicados en el tratamiento de datos</h2>
            <p>
              En el tratamiento de sus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos (RGPD):
            </p>

            <p>
              </p><ul>
                <li>
                  Principio de licitud, lealtad y transparencia: El Titular siempre requerirá el consentimiento para el tratamiento de los datos personales que puede ser para uno o varios fines específicos sobre los que el Titular informará al Usuario previamente con absoluta transparencia.
                </li>
                <li>
                  Principio de minimización de datos: El Titular solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.
                </li> 
                <li>
                  Principio de limitación del plazo de conservación: El Titular mantendrá los datos personales recabados durante el tiempo estrictamente necesario para el fin o los fines del tratamiento. El Titular informará al Usuario del plazo de conservación correspondiente según la finalidad.
                  <br />En el caso de suscripciones, el Titular revisará periódicamente las listas y eliminará aquellos registros inactivos durante un tiempo considerable.
                </li>
                <li>
                  Principio de integridad y confidencialidad: Los datos personales recabados serán tratados de tal manera que su seguridad, confidencialidad e integridad está garantizada.
                  <br />El Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.
                </li>
              </ul>
            <p></p>

            <h2>Obtención de datos personales</h2>
            <p>
              Para navegar por el sitio Web no es necesario que facilite ningún dato personal.
            </p>


            <h2>Derechos</h2>
            <p>
              El Titular le informa que sobre sus datos personales tiene derecho a:
            </p>
            <p>
              </p><ul>
                <li>Solicitar el acceso a los datos almacenados.</li>
                <li>Solicitar una rectificación o la supresión.</li>
                <li>Solicitar la limitación de su tratamiento.</li>
                <li>Oponerse al tratamiento.</li>
              </ul>
            <p></p>
            <p>
              No puede ejercitar el derecho a la portabilidad de los datos.
            </p>

            <p>
              El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento, puede dirigirse al Titular y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, oponerse al tratamiento, limitar su uso o solicitar la supresión de esos datos en los ficheros del Titular.
            </p>

            <p>  
              Para ejercitar sus derechos tiene que enviar su petición junto con una fotocopia del Documento Nacional de Identidad o equivalente a&nbsp;
              la dirección de correo electrónico:&nbsp;hello@roberherraiz.com
            </p>

            <p>
              El ejercicio de estos derechos no incluye ningún dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.
            </p>

            <p>
              Tiene derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si considera que el tratamiento de datos personales que le conciernen infringe el Reglamento.
            </p>

            <h2 id="finalidad-del-tratamiento-de-datos-personales">Finalidad del tratamiento de datos personales</h2>
            <p>
              Cuando usted se conecta al Sitio Web para mandar un correo al Titular, se suscribe a su boletín  está facilitando información de carácter personal de la que el responsable es el Titular. Esta información puede incluir datos de carácter personal como pueden ser su dirección IP, nombre y apellidos, dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, da su consentimiento para que su información sea recopilada, utilizada, gestionada y almacenada por 
              
              —
              AWS
              
              —
                sólo como se describe en las páginas:
                </p><ul>
                  <li><a href="/legal">Aviso Legal</a></li>
                  <li><a href="/privacy">Política de Privacidad</a></li>
                </ul>
            <p></p>
            <p>
              Los datos personales y la finalidad del tratamiento por parte del Titular es diferente según el sistema de captura de información:
            </p>
            <p>
              </p><ul>    
              </ul> 
            <p></p>
            <p>   
              Existen otras finalidades por las que el Titular trata datos personales:
            </p>
            <p>
              </p><ul>
                <li>
                  Para garantizar el cumplimiento de las condiciones recogidas en la página de Aviso Legal y de  la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden al Sitio Web a garantizar la confidencialidad de los datos personales que recoge.
                </li>
                <li>
                  Para apoyar y mejorar los servicios que ofrece este Sitio Web.
                </li>
                <li>
                  Para analizar la navegación de los usuarios. El Titular recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en el ordenador del Usuario cuando navega por el Sitio Web cuyas características y finalidad están detalladas en la página de  <a href="https://web.archive.org/web/20230327222109/https://roberherraiz.com/politica-de-cookies/">Política de Cookies</a>.
                </li>
              </ul>
            <p></p>


            <h2>Seguridad de los datos personales</h2>
            <p>
              Para proteger sus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.
            </p>
              <p>
                El sitio Web está alojado en:  AWS. La seguridad de los datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puede consultar su política de privacidad para tener más información.
              </p>


              <p>
                El Titular informa al Usuario de que sus datos personales no serán cedidos a terceras organizaciones, con la salvedad de que dicha cesión de datos esté amparada en una obligación legal o cuando la prestación de un servicio implique la necesidad de una relación contractual con un encargado de tratamiento. En este último caso, solo se llevará a cabo la cesión de datos al tercero cuando el Titular disponga del consentimiento expreso del Usuario.
              </p>
              <p>
                Sin embargo, en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se requerirá consentimiento al Usuario informando sobre la identidad del colaborador y la finalidad de la colaboración. Siempre se realizará con los más estrictos estándares de seguridad.
              </p>


            <h2>Contenido de otros sitios web</h2>
            <p>
              Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si hubiera visitado la otra web.
            </p>
            <p>
              Estos sitios Web pueden recopilar datos sobre usted, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar su interacción usando este código.
            </p>


            <h2>Política de Cookies</h2>
            <p>
              Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una información que se almacena en su navegador web. 
              
              </p><p>
                Puede consultar toda la información relativa a la política de recogida y tratamiento de las cookies en la página de  <a href="/cookies">Política de Cookies</a>.
              </p>
            <p></p>



            <h2>Legitimación para el tratamiento de datos</h2>
            <p>
              La base legal para el tratamiento de sus datos es:
              </p><ul>
                <li>
                  El consentimiento del interesado.
                </li> 
              </ul>
            <p></p>


            <h2>Categorías de datos personales</h2>
            <p>
              Las categorías de datos personales que trata el Titular son:
            </p>
            <p>
              </p><ul>    
                <li>Datos identificativos.</li>
                <li>No se tratan categorías de datos especialmente protegidos.</li>
              </ul>
            <p></p>


            <h2>Conservación de datos personales</h2>
            <p>  
              Los datos personales proporcionados al Titular se conservarán hasta que solicite su supresión.
            </p>


            <div id="destinatarios">
            <h2>Destinatarios de datos personales</h2>
            <p>
              </p><ul>  
                <li>
                  <b>Google Analytics</b> es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google").      
                  <br />Google Analytics utiliza "cookies", que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los Usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo la dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.
                  <br />Más información en:&nbsp;<a href="https://policies.google.com/privacy?hl=es-ES" rel="nofollow" target="_blank">https://analytics.google.com</a>
                </li>   
              </ul>
            <p></p>
            </div>

              <p>
                Puede consultar como Google gestiona la privacidad en lo que respecta al uso de las cookies y otra información en la página de Política de privacidad de Google:&nbsp;<a target="_blank" href="https://policies.google.com/privacy?hl=es" rel="nofollow">https://policies.google.com/privacy?hl=es</a>
              </p>
              
              <p>
                También puede ver una lista de los tipos de cookies que utiliza Google y sus colaboradores y toda la información relativa al uso que hacen de las cookies publicitarias en:
              </p>
                <ul>
                  <li><a target="_blank" href="https://policies.google.com/technologies/types?hl=es" rel="nofollow">Tipos de cookies que utiliza Google</a></li>
                  <li><a target="_blank" rel="nofollow" href="https://policies.google.com/technologies/ads?hl=es">Cómo utiliza Google las cookies en publicidad</a>.</li>
                </ul>


            <h2>Navegación Web</h2>
            <p>
              Al navegar por el Sitio Web se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarle.
            </p>

            <p>
              El sitio Web utiliza los siguientes servicios de análisis de terceros:
            </p>

            <p>
              </p><ul>
                <li>Google Analytics.</li>
                
                
              </ul>
            <p></p>
            <p>
              El Titular utiliza la información obtenida para obtener datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y para recopilar información demográfica.
            </p>
            <p>
              El Titular no se hace responsable del tratamiento de los datos personales que realicen las páginas web a las que pueda acceder a través de los distintos enlaces que contiene el Sitio Web.
            </p>


            <h2>Exactitud y veracidad de los datos personales</h2>
            <p>
              Usted se compromete a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.
            </p>
            <p>
              Como Usuario del Sitio Web es el único responsable de la veracidad y corrección de los datos remitidos al Sitio Web exonerando al Titular de cualquier responsabilidad al respecto.
            </p>


            <h2>Aceptación y consentimiento</h2>
            <p> 
              Como Usuario del Sitio Web declara haber sido informado de las condiciones sobre protección de datos de carácter personal, acepta y consiente el tratamiento de los mismos por parte del Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.
            </p>
            <p>
              Para contactar con el Titular, suscribirse a un boletín o realizar comentarios en este sitio Web tiene que aceptar la presente Política de Privacidad.
            </p>


            <h2>Cambios en la Política de Privacidad</h2>
            <p>
              El Titular se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.
            </p>
            <p> 
              Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
            </p>

            <h2>Quiénes somos</h2>



            <p>La dirección de nuestra web es: https://roberherraiz.com.</p>



            <h2>Qué datos personales recogemos y por qué los recogemos</h2>



            <h3>Comentarios</h3>



            <p>Cuando los visitantes dejan comentarios en la web, recopilamos los datos que se muestran en el formulario de comentarios, así como la dirección IP del visitante y la cadena de agentes de usuario del navegador para ayudar a la detección de spam.</p>



            <p>Una cadena anónima creada a partir de tu dirección de correo electrónico (también llamada hash) puede ser proporcionada al servicio de Gravatar para ver si la estás usando. La política de privacidad del servicio Gravatar está disponible aquí: https://automattic.com/privacy/. Después de la aprobación de tu comentario, la imagen de tu perfil es visible para el público en el contexto de tu comentario.</p>



            <h3>Medios</h3>



            <p>Si subes imágenes a la web, deberías evitar subir imágenes con datos de ubicación (GPS EXIF) incluidos. Los visitantes de la web pueden descargar y extraer cualquier dato de ubicación de las imágenes de la web.</p>



            <h3>Formularios de contacto</h3>



            <h3>Cookies</h3>



            <p>Si dejas un comentario en nuestro sitio puedes elegir guardar tu nombre, dirección de correo electrónico y web en cookies. Esto es para tu comodidad, para que no tengas que volver a rellenar tus datos cuando dejes otro comentario. Estas cookies tendrán una duración de un año.</p>



            <p>Si tienes una cuenta y te conectas a este sitio, instalaremos una cookie temporal para determinar si tu navegador acepta cookies. Esta cookie no contiene datos personales y se elimina al cerrar el navegador.</p>



            <p>Cuando accedas, también instalaremos varias cookies para guardar tu información de acceso y tus opciones de visualización de pantalla. Las cookies de acceso duran dos días, y las cookies de opciones de pantalla duran un año. Si seleccionas «Recuérdarme», tu acceso perdurará durante dos semanas. Si sales de tu cuenta, las cookies de acceso se eliminarán.</p>



            <p>Si editas o publicas un artículo se guardará una cookie adicional en tu navegador. Esta cookie no incluye datos personales y simplemente indica el ID del artículo que acabas de editar. Caduca después de 1 día.</p>



            <h3>Contenido incrustado de otros sitios web</h3>



            <p>Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras webs se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.</p>



            <p>Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.</p>



            <h3>Analítica</h3>



            <h2>Con quién compartimos tus datos</h2>



            <h2>Cuánto tiempo conservamos tus datos</h2>



            <p>Si dejas un comentario, el comentario y sus metadatos se conservan indefinidamente. Esto es para que podamos reconocer y aprobar comentarios sucesivos automáticamente, en lugar de mantenerlos en una cola de moderación.</p>



            <p>De los usuarios que se registran en nuestra web (si los hay), también almacenamos la información personal que proporcionan en su perfil de usuario. Todos los usuarios pueden ver, editar o eliminar su información personal en cualquier momento (excepto que no pueden cambiar su nombre de usuario). Los administradores de la web también pueden ver y editar esa información.</p>



            <h2>Qué derechos tienes sobre tus datos</h2>



            <p>Si tienes una cuenta o has dejado comentarios en esta web, puedes solicitar recibir un archivo de exportación de los datos personales que tenemos sobre ti, incluyendo cualquier dato que nos hayas proporcionado. También puedes solicitar que eliminemos cualquier dato personal que tengamos sobre ti. Esto no incluye ningún dato que estemos obligados a conservar con fines administrativos, legales o de seguridad.</p>



            <h2>Dónde enviamos tus datos</h2>



            <p>Los comentarios de los visitantes puede que los revise un servicio de detección automática de spam.</p>



            <h2>Tu información de contacto</h2>



            <h2>Información adicional</h2>



            <h3>Cómo protegemos tus datos</h3>



            <h3>Qué procedimientos utilizamos contra las brechas de datos</h3>



            <h3>De qué terceros recibimos datos</h3>



            <h3>Qué tipo de toma de decisiones automatizada y/o perfilado hacemos con los datos del usuario</h3>



            <h3>Requerimientos regulatorios de revelación de información del sector</h3>
          </div>		
        </div>
      </div>
    </section>
  );
};

export default Privacy;
