import React from "react";
import Typewriter from "typewriter-effect";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";

const Principal = () => {
    return (
<div className="hero-content d-flex fullscreen-with-header">
<div className={window.innerWidth > 768 ? "container my-auto py-5 py-lg-0" : "container py-2 py-lg-0" }>
  <div className="row py-4">
    <div className="col-lg-7 text-center text-lg-start align-self-center order-1 order-lg-0">
      <h1 className={window.innerWidth > 768 ? "text-12 fw-300 mb-0 text-uppercase" : "text-8 fw-300 mb-0 text-uppercase"}>
        Hola, soy {window.innerWidth < 768 && (<br />) } Rober Herraiz
      </h1>
      <h2 className={window.innerWidth > 768 ? "text-21 fw-600 text-uppercase mb-0 ms-n1" : "text-14 fw-600 text-uppercase mb-0 ms-n1" }>
        <Typewriter
          options={{
            strings: ["Developer", "Frontend", "Backend"],
            autoStart: true,
            loop: true,
          }}
        />{" "}
      </h2>
      <p className={window.innerWidth > 768 ? "text-5" : "text-4"}>trabajando principalmente con React y Node.js desde 🇮🇨 🇪🇸</p>
      <Link
        className="btn btn-dark rounded-0 smooth-scroll mt-3"
        smooth="easeInOutQuint"
        duration={scrollDuration}
        style={{ cursor: "pointer" }}
        to="portfolio"
      >
        Mira mi portfolio
      </Link>
      <Link
        className="btn btn-link text-dark smooth-scroll mt-3"
        smooth="easeInOutQuint"
        duration={scrollDuration}
        style={{ cursor: "pointer" }}
        to="contact"
      >
        Contacta conmigo
        <span className="text-4 ms-2">
          <i className="far fa-arrow-alt-circle-down" />
        </span>
      </Link>{" "}
    </div>
    <div className="col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1"
    style={{maxWidth: "75vw", display: "inherit", flexWrap: "none", alignItems: "center", justifyContent: "center",  marginLeft: "auto", marginRight: "auto"}}
    >
      <div className= { window.innerWidth > 768 ? "bg-light rounded-pill d-inline-block p-3 shadow-lg wow zoomIn" : "bg-light rounded-pill d-inline-block p-2 shadow-lg wow zoomIn"}>
        {" "}
        <img
          className="img-fluid rounded-pill d-block"
          src={window.innerWidth > 768 ? "images/web-developer.jpg" : "images/web-developer-sm.jpg"}
          title="Soy Rober Herraiz"
          alt="Soy Rober Herraiz"
        />
      </div>
    </div>
  </div>
</div>
<Link
  className="scroll-down-arrow text-dark smooth-scroll"
  smooth="easeInOutQuint"
  duration={scrollDuration}
  style={{ cursor: "pointer" }}
  to="about"
>
  <span className="animated">
    <i className="fas fa-arrow-down" />
  </span>
</Link>{" "}
</div>
  );
};

export default Principal;