import React from "react";
import videoHorizontal from "../../../videos/tgg-horizontal.mp4";
import Principal from "./Principal";

const StandardMenuVideoBgIntro = () => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-primary" />
        <div className="player hero-bg ">
          <video
            src={
              window.innerWidth > 768
              ? videoHorizontal
              : false // was videoVerticla but it is a bug on mobile when scrolling up
            }
            autoPlay
            muted
            loop
            style={{
              width: "100%",
              height: "100vh",
              minHeight: "100%",
              objectFit: "cover",
            }}
          ></video>
        </div>
       <Principal />
      </div>
    </section>
  );
};

export default StandardMenuVideoBgIntro;
