import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <div style={{marginBottom: "15px"}}>
            {/* Legal */}
            <a href="/legal" className="text-white" style={{marginRight: "25px"}}>
              Aviso Legal
            </a>
            {/* Privacidad */}
            <a href="/privacy" className="text-white">
              Política de privacidad
            </a>
            {/* Cookies */}
            <a href="/cookies" className="text-white ms-4">
              Política de cookies
            </a>

            </div>

            <p className="mb-2 mb-lg-0">
              Copyright © 2024{" "}
              <a className="fw-600" href="/">
                Rober Herraiz
              </a>
              . All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
