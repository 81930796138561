import React from "react";
import Principal from "./Principal";

const StandardMenuImgBgIntro = () => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask" />
        <div
          className="hero-bg parallax"
          style={{ backgroundColor: "#00fe9c" }}
        />
       <Principal />
      </div>
    </section>
  );
};

export default StandardMenuImgBgIntro;
