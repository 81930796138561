import "./sass/cookieConsent.css";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import es from "javascript-time-ago/locale/es.json";
import CookieConsent from "@grrr/cookie-consent";
import ReactGA from "react-ga4";

TimeAgo.addDefaultLocale(es);
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(es);

export const cookieConsent = CookieConsent({
  labels: {
    title: "Cookies y privacidad",
    description:
      '<span>Este sitio web, utiliza cookies propias y de terceros para saber cómo utilizas nuestro sitio web y hacer estadísticas y analíticas. <a href="/cookies">Más información</a></span>',
    button: {
      // acceptAll: 'Aceptar',
      default: "Aceptar",
    },
  },
  // acceptAllButton: true,
  cookies: [
    {
      id: "own",
      label: "Funcionamiento",
      description:
        "Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.",
      checked: true,
      required: true,
    },
    {
      id: "statistics",
      label: "Estadísticas",
      description:
        "Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima.",
      checked: true,
    },
  ],
});

if (cookieConsent.isAccepted("statistics")) {
    ReactGA.initialize("G-7XFSBXH8QY");
    ReactGA.send(window.location.pathname + window.location.search);
}


// Configure cookie consent button
setTimeout(() => {
  const buttonConfigure = document.createElement("button");
  buttonConfigure.innerHTML = "Configurar";
  buttonConfigure.className = "cookie-consent__button2";
  buttonConfigure.onclick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    Array.prototype.map.call(
      document.querySelectorAll(
        "label.cookie-consent__option > input[type=checkbox]:not(:disabled)"
      ),
      (el) => {
        // eslint-disable-next-line no-param-reassign
        el.checked = false;
      }
    );
    document.getElementsByClassName("cookie-consent")[0].className +=
      " show-preferences";
  };

  const form = document.querySelector(".cookie-consent form");
  form?.appendChild(buttonConfigure);
});

export default cookieConsent;