import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DEVELOPMENT: "DEVELOPMENT",
    MANAGEMENT: "MANAGEMENT"
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [

    {
      title: "Tenerife GG",
      featured: true,
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Desarrollo web del aclamado como mayor evento tecnológico de Canarias. Desarrollo del sistema de compra de entradas, gestión de torneos, contenidos de la web y gestión de las acreditaciones.",
        technologies: "React, MUI, Node.js, GraphQL, Prisma, PostgreSQL",
        client: "Liga Canaria de Esports",
        date: "2021 - 2023",
        industry: "Esports",
        url: {
          name: "www.tenerife.gg",
          link: "https://tenerife.gg",
        },

        sliderImages: [
          "images/projects/tgg-1.jpeg",
          "images/projects/tgg-2.jpeg",
          "images/projects/tgg-3.jpeg",
        ],
      },

      thumbImage: "images/projects/tgg-thumb.jpg",
      categories: [filters.DEVELOPMENT],

    },
    {
      title: "Liga Canaria de Esports",
      featured: true,
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Desarrollo web de la Liga Canaria de Esports, una plataforma para la gestión de torneos de videojuegos en Canarias.",
        technologies: "React, MUI, Node.js, GraphQL, Prisma, PostgreSQL",
        client: "Liga Canaria de Esports",
        date: "2021 - 2023",
        industry: "Esports",
        url: {
          name: "www.ligacanariaesports.com",
          link: "https://ligacanariaesports.com",
        },

        sliderImages: [
          "images/projects/lces-1.jpeg",
          "images/projects/lces-2.jpeg",
        ],
      },

      thumbImage: "images/projects/lces-thumb.jpg",
      categories: [filters.DEVELOPMENT, filters.MANAGEMENT],

    },
    
    {
      title: "Gamelab Tenerife",
      featured: true,
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Desarrollo web de la Liga Canaria de Esports, una plataforma para la gestión de torneos de videojuegos en Canarias.",
        technologies: "React, MUI, Node.js, GraphQL, Prisma, PostgreSQL",
        client: "Gamelab",
        date: "2022 - 2022",
        industry: "Profesional",
        url: {
          name: "www.gamelab.hdgaming.es",
          link: "https://gamelab.hdgaming.es",
        },

        sliderImages: [
          "images/projects/gamelab-1.jpeg",
          "images/projects/gamelab-2.jpeg",
          "images/projects/gamelab-3.jpeg"
        ],
      },

      thumbImage: "images/projects/gamelab-thumb.jpg",
      categories: [filters.DEVELOPMENT],

    },
    {
      title: "Simseries",
      featured: true,
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Web de competición de juegos de Simracing, con torneos y contenidos.",
        technologies: "React, MUI, Node.js, GraphQL, Prisma, PostgreSQL",
        client: "Liga Canaria de Esports",
        date: "2021 - 2023",
        industry: "Esports",
        url: {
          name: "www.simseries.net",
          link: "https://simseries.net",
        },

        sliderImages: [
          "images/projects/simseries-1.jpeg",
          "images/projects/simseries-2.jpeg",
        ],
      },

      thumbImage: "images/projects/simseries-thumb.jpg",
      categories: [filters.DEVELOPMENT],

    },
    {
      title: "TGX",
      featured: true,
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "TGX nació como una plataforma para el gamer, con contenidos de videojuegos, tecnología y entretenimiento. Además, era una red social en la que podías además crear tus propios torneos y competir con amigos a una infinidad de juegos.",
        client: "Gaming Experience",
        date: "2016 - 2020",
        industry: "Plataforma de videojuegos - Red social",


        sliderImages: [
          "images/projects/tgx-1.jpeg",
          "images/projects/tgx-2.png",
        ],
      },

      thumbImage: "images/projects/tgx-thumb.jpg",
      categories: [filters.MANAGEMENT],

    },
    // {
    //   title: "Intel Rising E-Stars",
    //   featured: false,
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Actividades desarrolladas para Intel España en el programa Rising E-Stars, un programa de formación y competición para jóvenes talentos en el mundo de los esports.",
    //     client: "Intel",
    //     date: "2017 - 2018",
    //     industry: "Esports",
    //     // url: {
    //     //   name: "www.ligacanariaesports.com",
    //     //   link: "https://ligacanariaesports.com",
    //     // },

    //     sliderImages: [
    //       "images/projects/rising-1.jpeg",
    //       "images/projects/rising-2.jpg",
    //     ],
    //   },

    //   thumbImage: "images/projects/risingestars-thumb.jpg",
    //   categories: [filters.MANAGEMENT],

    // },
    {
      title: "Juegos Escolares Minecraft Education",
      featured: true,
      type: types.VIDEO,
      video: {
        vimeo: false,
        id: "z2Ri9Z3MHHQ",
      },
      thumbImage: "images/projects/jeme-thumb.jpg",
      categories: [filters.MANAGEMENT],
    },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () =>{
    setFilterKey(key);
  };

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  const getCategoryName = (key) => {
    switch (key) {
      case filters.DEVELOPMENT:
        return "Desarrollo";
      case filters.MANAGEMENT:
        return "Dirección de Proyectos";
      default:
        return "";
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Estos son algunos de mis proyectos
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                TODOS
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 && (
                projectsData
                  .map((project, index) => (
                    <div 
                    className={ 
                      "col-sm-6 col-lg-4 filter-item " + 
                      getFilterClasses(project.categories) 
                    } 
                    key={index} 
                  > 
                    <div className="portfolio-box"> 
                      <div className="portfolio-img"> 
                        <img 
                          onLoad={() => { 
                            setimagesLoaded(imagesLoaded + 1); 
                          }} 
                          className="img-fluid d-block portfolio-image" 
                          src={project.thumbImage} 
                          alt="" 
                        /> 
                        <div 
                          className="portfolio-overlay" 
                          onClick={() => { 
                            setSelectedProjectDetails(projectsData[index]); 
                            setIsOpen(true); 
                          }} 
                        > 
                          <button className="popup-ajax stretched-link border-0 p-0 "> 
                            {" "} 
                          </button> 
                          <div className="portfolio-overlay-details"> 
                            <p className="text-primary text-8"> 
                              {project.type === types.DOCUMENT && ( 
                                <i className="fas fa-file-alt"></i> 
                              )} 
                              {project.type === types.IMAGE && ( 
                                <i className="fas fa-image"></i> 
                              )} 
                              {project.type === types.VIDEO && ( 
                                <i className="fas fa-video"></i> 
                              )} 
                            </p> 
                            <h5 className="text-white text-5"> 
                              {project?.title} 
                            </h5> 
                            <span className="text-light">{getCategoryName(project?.categories[0])}</span> 
                          </div> 
                        </div> 
                      </div> 
                    </div> 
                  </div> 

                  ))
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
